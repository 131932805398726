<template>
  <div>
    <div class="loading" v-if="isLoading == true">
        <div class="sk-three-bounce">
          <div class="sk-child sk-bounce1"></div>
          <div class="sk-child sk-bounce2"></div>
          <div class="sk-child sk-bounce3"></div>
        </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-dropdown id="dropdown-dropright" dropright text="Gender" variant="primary" class="m-2">
            <b-dropdown-item @click="doFilterGender(filterGender = '')">All</b-dropdown-item>
            <b-dropdown-item @click="doFilterGender(filterGender = 'Male')">Male</b-dropdown-item>
            <b-dropdown-item @click="doFilterGender(filterGender = 'Female')">Female</b-dropdown-item>
          </b-dropdown>
          <b-dropdown id="dropdown-dropright" dropright text="Generation" variant="secondary" class="m-2">
            <b-dropdown-item @click="doFilterGeneration(filterGeneration = '')">All</b-dropdown-item>
            <b-dropdown-item @click="doFilterGeneration(filterGeneration = 'Babyboomer')">Babyboomer</b-dropdown-item>
            <b-dropdown-item @click="doFilterGeneration(filterGeneration = 'GenX')">Gen X</b-dropdown-item>
            <b-dropdown-item @click="doFilterGeneration(filterGeneration = 'GenY')">Gen Y</b-dropdown-item>
            <b-dropdown-item @click="doFilterGeneration(filterGeneration = 'GenZ')">Gen Z</b-dropdown-item>
            <b-dropdown-item @click="doFilterGeneration(filterGeneration = 'Alpha')">Alpha</b-dropdown-item>
          </b-dropdown>
          <b-dropdown id="dropdown-dropright" dropright text="Cluster" variant="warning" class="m-2">
            <b-dropdown-item @click="doFilterCluster(filterCluster = '')">All</b-dropdown-item>
            <b-dropdown-item @click="doFilterCluster(filterCluster = 'Short')">Short</b-dropdown-item>
            <b-dropdown-item @click="doFilterCluster(filterCluster = 'Medium')">Medium</b-dropdown-item>
            <b-dropdown-item @click="doFilterCluster(filterCluster = 'Long')">Long</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">
      <template slot="name-slot" slot-scope="prop">
        <span>{{ prop.rowData.name }}</span>
      </template>
      <template slot="email-slot" slot-scope="prop">
        <span>{{ prop.rowData.email }}</span>
      </template>
      <template slot="gender-slot" slot-scope="prop">
        <span>{{ prop.rowData.gender }}</span>
      </template>
      <template slot="age-slot" slot-scope="prop">
        <span>{{ prop.rowData.age }}</span>
      </template>
      <template slot="gen-slot" slot-scope="prop">
        <span>{{ prop.rowData.gen }}</span>
      </template>
      <template slot="cluster-slot" slot-scope="prop">
        <span>{{ prop.rowData.cluster }}</span>
      </template>
      <template slot="gold_balance-slot" slot-scope="prop">
        <span>{{ prop.rowData.gold_balance }}</span>
      </template>
      <template slot="bonus_gold_balance-slot" slot-scope="prop">
        <span>{{ prop.rowData.bonus_gold_balance }}</span>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import accounting from 'accounting'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo,
    },
    data() {
      return {
        filterGender: '',
        filterGeneration: '',
        filterCluster: '',
        isLoading: false,
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `bma/silent-users`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'name',
            sortField: 'name',
            direction: 'desc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: 'name-slot',
            sortField: 'name',
            title: 'Name'
          },
          {
            name: 'email-slot',
            sortField: 'email',
            title: 'Email'
          },
          {
            name: 'gender-slot',
            title: 'Gender'
          },
          {
            name: 'age-slot',
            title: 'Age'
          },
          {
            name: 'gen-slot',
            title: 'Generation'
          },
          {
            name: 'cluster-slot',
            title: 'Cluster'
          },
          {
            name: 'gold_balance-slot',
            title: 'Total Gold Amount '
          },
          {
            name: 'bonus_gold_balance-slot',
            title: 'Total Bonus Gold '
          }
        ]
      }
    },
    methods: {
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      doFilterGender (value) {
        this.$events.$emit('filter-gender-set', value)
      },
      doFilterGeneration (value) {
        this.$events.$emit('filter-generation-set', value)
      },
      doFilterCluster (value) {
        this.$events.$emit('filter-cluster-set', value)
      },
      resetFilterGender () {
        this.filterGender = ''  // clear the filter
        this.$events.$emit('filter-gender-reset')
      },
      resetFilterGeneration () {
        this.filterGeneration = ''  // clear the filter
        this.$events.$emit('filter-generation-reset')
      },
      resetFilterCategory () {
        this.filterCategory = ''  // clear the filter
        this.$events.$emit('filter-category-reset')
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        this.moreParams = {
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      formatNumber (value) {
        return accounting.formatNumber(value, 2)
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onFilterSet (filterText) {
          this.moreParams = {
              'filter': filterText
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterReset () {
          this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterGenderSet (filter) {
        this.moreParams = {
          'gender': filter
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterGenerationSet (filter) {
        this.moreParams = {
          'gen': filter
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterClusterSet (filter) {
        this.moreParams = {
          'cluster': filter
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
    },
    mounted() {
      this.$events.$on('filter-gender-set', eventData => this.onFilterGenderSet(eventData))
      this.$events.$on('filter-generation-set', eventData => this.onFilterGenerationSet(eventData))
      this.$events.$on('filter-cluster-set', eventData => this.onFilterClusterSet(eventData))
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
